var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-create create-form"},[_c('v-form',{ref:"adjustmentForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit(1)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"white-background main-create-page",attrs:{"fluid":""}},[_c('div',{staticClass:"p-5 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('h1',{staticClass:"form-title d-flex"},[(_vm.adjustmentId)?[_vm._v("Update")]:[_vm._v("Create")],_vm._v(" Adjustment ")],2)]),_c('v-col',{staticClass:"pt-0 text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mx-2 custom-bold-button text-white",attrs:{"disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":function($event){return _vm.onSubmit(1)}}},[_vm._v(" Save as Draft ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button text-white",attrs:{"disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":function($event){return _vm.onSubmit(3)}}},[_vm._v(" Approve and Adjust ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading,"outlined":""},on:{"click":_vm.goBack}},[_vm._v("Cancel ")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('table',{staticClass:"width-100 font-size-16"},[_c('tr',[_c('td',{staticClass:"py-2 font-weight-600",attrs:{"width":"200"}},[_c('label',[_vm._v("Reference #")])]),_c('td',{staticClass:"py-2"},[_c('v-text-field',{attrs:{"dense":"","filled":"","flat":"","label":"Reference #","hide-details":"","solo":"","rules":[
                          _vm.validateRules.minLength(
                            _vm.adjustment.reference,
                            'Reference #',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.adjustment.reference,
                            'Reference #',
                            100
                          ) ],"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan"},model:{value:(_vm.adjustment.reference),callback:function ($$v) {_vm.$set(_vm.adjustment, "reference", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.reference"}})],1)]),_c('tr',[_c('td',{staticClass:"py-2 font-weight-600",attrs:{"width":"200"}},[_c('label',{staticClass:"required"},[_vm._v("Date")])]),_c('td',{staticClass:"py-2"},[_c('DatePicker',{attrs:{"solo":"","mandatory":"","pageLoading":_vm.pageLoading},on:{"update:date-picker":_vm.updateDate,"update:pageLoading":function($event){_vm.pageLoading=$event},"update:page-loading":function($event){_vm.pageLoading=$event}}})],1)]),_c('tr',[_c('td',{staticClass:"py-2 font-weight-600",attrs:{"width":"200"}},[_c('label',{staticClass:"required"},[_vm._v("Reason")])]),_c('td',{staticClass:"py-2"},[_c('v-text-field',{attrs:{"dense":"","filled":"","flat":"","label":"Reason","hide-details":"","solo":"","rules":[
                          _vm.validateRules.required(_vm.adjustment.reason, 'Reason'),
                          _vm.validateRules.minLength(
                            _vm.adjustment.reason,
                            'Reason',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.adjustment.reason,
                            'Reason',
                            255
                          ) ],"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan"},model:{value:(_vm.adjustment.reason),callback:function ($$v) {_vm.$set(_vm.adjustment, "reason", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.reason"}})],1)]),_c('tr',[_c('td',{staticClass:"py-2 font-weight-600",staticStyle:{"vertical-align":"text-bottom"},attrs:{"width":"200"}},[_vm._v(" Description ")]),_c('td',{staticClass:"py-2"},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Description","solo":"","flat":"","rules":[
                          _vm.validateRules.minLength(
                            _vm.adjustment.description,
                            'Description',
                            2
                          ),
                          _vm.validateRules.maxLength(
                            _vm.adjustment.description,
                            'Description',
                            1024
                          ) ],"row-height":"25","hide-details":""},model:{value:(_vm.adjustment.description),callback:function ($$v) {_vm.$set(_vm.adjustment, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.description"}})],1)])])]),_c('v-col',{staticClass:"custom-line-item-template",attrs:{"md":"11"}},[_c('table',{staticClass:"custom-border-top width-100"},[_c('thead',[_c('tr',[_c('th',{staticClass:"font-size-16 py-2",attrs:{"colspan":"2","width":"500"}},[_vm._v(" Product ")]),_c('th',{staticClass:"pl-5 font-size-16 py-2"},[_vm._v(" Quantity Available ")]),_c('th',{staticClass:"pl-5 font-size-16 py-2"},[_vm._v(" New Quantity On Hand "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v("mdi-progress-question")])]}}])},[_c('span',[_vm._v("Maximum allowed Qty is 9999")])])],1),_c('th',{staticClass:"pl-5 font-size-16 py-2"},[_vm._v(" Quantity Adjusted "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v("mdi-progress-question")])]}}])},[_c('span',[_vm._v("Maximum allowed Qty is 9999")])])],1)]),_c('tr',[_c('td',{staticClass:"custom-border-bottom first-border",attrs:{"colspan":"5"}})])]),_c('tbody',_vm._l((_vm.adjustmentItems),function(item,index){return _c('tr',{key:index,staticClass:"custom-line-item"},[_c('td',{staticClass:"pt-4"},[_c('v-img',{staticClass:"margin-auto",attrs:{"lazy-src":_vm.$defaultImage,"src":item.image,"width":"100"}})],1),_c('td',{staticClass:"pl-5 pt-4",class:{ 'pb-4': !_vm.adjustmentItems[index + 1] }},[_c('v-autocomplete',{staticClass:"remove-border-radius hide-border-bottom",attrs:{"clearable":"","items":_vm.lineItemList,"hide-details":"","dense":"","filled":"","flat":"","label":"Search...","solo":"","rules":[
                            _vm.validateRules.required(item.product, 'Product') ],"item-color":"cyan","color":"cyan","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"item-text":"name","item-value":"id","return-object":""},on:{"change":function($event){return _vm.updateLineItem(item.product, index)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('Nothing Found.')}})],1)]},proxy:true},{key:"item",fn:function(ref){
                          var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text text-uppercase",attrs:{"color":"cyan"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',{staticClass:"max-width-350px"},[_c('v-list-item-title',{staticClass:"font-size-16 text-ellipsis pb-1 text-capitalize",domProps:{"textContent":_vm._s(item.name)}}),(item.description)?_c('v-list-item-subtitle',{staticClass:"font-size-14 text-ellipsis pb-1",domProps:{"textContent":_vm._s(item.description)}}):_vm._e(),_c('v-list-item-subtitle',{staticClass:"font-size-14 pb-1"},[_c('span',{staticClass:"font-weight-500"},[_vm._v("Selling Cost:")]),_vm._v(" "+_vm._s(_vm.formatNumber(item.selling_cost)))]),_c('v-list-item-subtitle',{staticClass:"font-size-14"},[_c('span',{staticClass:"font-weight-500"},[_vm._v("Available Stock:")]),_vm._v(" "+_vm._s(_vm.accountingUnFormat(item.available_stock)))])],1)]}}],null,true),model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}),_c('v-textarea',{staticClass:"remove-border-radius",attrs:{"auto-grow":"","dense":"","filled":"","flat":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Description","solo":"","color":"cyan","hide-details":"","row-height":"20","rules":[
                            _vm.validateRules.minLength(
                              item.description,
                              'Description',
                              2
                            ),
                            _vm.validateRules.maxLength(
                              item.description,
                              'Description',
                              1024
                            ) ]},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.description"}})],1),_c('td',{staticClass:"pl-5 pt-4"},[_c('v-text-field',{staticClass:"remove-border-radius",attrs:{"dense":"","filled":"","flat":"","color":"cyan","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Quantity Available","type":"number","min":"0","max":"9999","hide-details":"","solo":"","readonly":""},model:{value:(item.available_qty),callback:function ($$v) {_vm.$set(item, "available_qty", _vm._n($$v))},expression:"item.available_qty"}})],1),_c('td',{staticClass:"pl-5 pt-4"},[_c('v-text-field',{ref:'stock_on_hand_' + index,refInFor:true,staticClass:"remove-border-radius",attrs:{"dense":"","filled":"","flat":"","color":"cyan","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"New Quantity On Hand","type":"number","max":"9999","hide-details":"","solo":"","rules":[
                            _vm.validateRules.required(
                              item.stock_on_hand,
                              'New Quantity On Hand'
                            ) ]},on:{"keyup":function($event){return _vm.fixQuantity(
                              item.stock_on_hand,
                              index,
                              'stock_on_hand'
                            )}},model:{value:(item.stock_on_hand),callback:function ($$v) {_vm.$set(item, "stock_on_hand", _vm._n($$v))},expression:"item.stock_on_hand"}})],1),_c('td',{staticClass:"pl-5 pt-4"},[_c('v-text-field',{staticClass:"remove-border-radius",attrs:{"dense":"","filled":"","flat":"","color":"cyan","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Quantity Adjusted","type":"number","max":"9999","hide-details":"","solo":"","rules":[
                            _vm.validateRules.required(
                              item.adjusted_qty,
                              'Quantity Adjusted'
                            ) ]},on:{"keyup":function($event){return _vm.fixQuantity(
                              item.adjusted_qty,
                              index,
                              'adjusted_qty'
                            )}},model:{value:(item.adjusted_qty),callback:function ($$v) {_vm.$set(item, "adjusted_qty", _vm._n($$v))},expression:"item.adjusted_qty"}}),(_vm.adjustmentItems.length > 1)?[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"my-2 custom-bold-button",attrs:{"tile":"","disabled":_vm.pageLoading,"color":"red","outlined":"","small":""},on:{"click":function($event){return _vm.removeLineItem(index, item.id)}}},[_c('strong',[_vm._v("Delete")])])],1)]:_vm._e()],2)])}),0),(!_vm.$route.query.product)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"custom-border-top",attrs:{"colspan":"5"}},[_c('div',[_c('v-btn',{staticClass:"mx-2 my-2 custom-bold-button white--text",attrs:{"disabled":_vm.pageLoading,"color":"cyan"},on:{"click":_vm.addLineItem}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-format-align-left")]),_vm._v(" Add Line Item ")],1)],1)])])]):_vm._e()])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }